import { Alert, FieldGrid } from "@app/design-system";
import styled from "styled-components";
import type { ResourceAppliance } from "../../../../.rest-hooks/types/resources.yml/resourceAppliance";
import { applianceGpsStatusLabels } from "../../../config/applianceGpsStatus";
import formatDuration from "../../../utils/formatDuration/formatDuration";
import AppliancePositionMap from "../AppliancePositionMap";

const StyledApplianceCurrentLocation = styled.div`
  display: grid;
  gap: 12px;
`;

interface ApplianceCurrentLocationProps {
  appliance: ResourceAppliance;
}

const ApplianceCurrentLocation = ({
  appliance,
}: ApplianceCurrentLocationProps) => {
  if (!appliance.attributes.currentLocation) {
    return (
      <Alert variant="info" title="Unable to determine appliance location" />
    );
  }

  const reportAge = formatDuration(
    { seconds: appliance.attributes.currentLocation.reportAge },
    "hm-zero",
  );

  return (
    <StyledApplianceCurrentLocation>
      <AppliancePositionMap resourceId={appliance.id} />
      <FieldGrid>
        <FieldGrid.Item label="Report age:">{reportAge}</FieldGrid.Item>
        <FieldGrid.Item label="GPS status:">
          {
            applianceGpsStatusLabels[
              appliance.attributes.currentLocation.gpsStatus
            ]
          }
        </FieldGrid.Item>
      </FieldGrid>
    </StyledApplianceCurrentLocation>
  );
};

export default ApplianceCurrentLocation;
