import { add, differenceInHours, intervalToDuration } from "date-fns";

export type DurationFormat = "hm-zero"; // Single char hours and minutes with zeroes.

function formatDuration(duration: Duration, format: DurationFormat): string {
  const i: Interval = { start: 0, end: add(0, duration) };
  const d = intervalToDuration(i);

  switch (format) {
    case "hm-zero":
      return `${differenceInHours(i.end, i.start, {
        roundingMethod: "trunc",
      })}h ${d.minutes}m`;

    default:
      throw new Error(`unknown format: ${format}`);
  }
}

export default formatDuration;
